import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Flipped } from 'react-flip-toolkit';
import { theme } from '../theme';
import { Layout } from '../layouts';

import { AnimateAfterFlip } from '../components/Animations/AnimateAfterFlip';
import { Footer } from '../components/Footer';
import { Container } from '../components/Container';
import { Heading } from '../components/Text/Heading';
import { BlogPostFooter } from '../components/BlogPostFooter';
import { AUTHORS } from '../components/Authors';
import { Helmet } from 'react-helmet';

const MAX_POST_HEADER_HEIGHT = 750;

export interface BlogPostTemplateProps {
  data: GatsbySchema.Query;
}

export interface BlogPostTemplateState {
  flipping?: boolean;
}

export default class BlogPostTemplate extends React.Component<
  BlogPostTemplateProps,
  BlogPostTemplateState
> {
  state: BlogPostTemplateState = {};

  handleStart = () => this.setState({ flipping: true });

  handleComplete = () => this.setState({ flipping: false });

  render() {
    const {
      data: {
        markdownRemark: {
          html,
          frontmatter: { slug, title, author, image, description, date },
        },
      },
    } = this.props;
    const { flipping } = this.state;

    return (
      <Layout>
        <Helmet
          title={title}
          meta={[
            { name: 'description', content: description },
            // Facebook OpenGraph Meta Tags
            { property: 'og:title', content: title },
            { property: 'og:type', content: 'article' },
            { property: 'og:description', content: description },
            { property: 'og:url', content: `https://palmer.net/blog/${slug}` },
            {
              property: 'og:image',
              content: `https://palmer.net${image.childImageSharp.sizes.src}`,
            },
            {
              property: 'og:site_name',
              content: 'Palmer',
            },
            { property: 'og:locale', content: 'en_US' },
            { property: 'article:author', content: author },
            { property: 'article:published_time', content: date },

            // Twitter Meta Tags
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:site', content: '@palmergrouphq' },
            {
              name: 'twitter:url',
              content: `https://palmer.net/blog/${slug}`,
            },
            { name: 'twitter:title', content: `${title} | The Palmer Group` },
            {
              name: 'twitter:description',
              content: description.slice(0, 200),
            },
            {
              name: 'twitter:image',
              content: `https://palmer.net${image.childImageSharp.sizes.src}`,
            },
          ]}
        />
        <Container padding={['left', 'right', 'bottom']} maxWidth={700}>
          <Flipped
            flipId={`${slug}-card`}
            onStart={this.handleStart}
            onComplete={this.handleComplete}
          >
            <div
              css={{
                position: 'sticky',
                top: -MAX_POST_HEADER_HEIGHT,
              }}
            >
              <Flipped inverseFlipId={`${slug}-card`}>
                <div>
                  {image && (
                    <Flipped flipId={`${slug}-photo`}>
                      <div
                        css={{
                          marginLeft: '-1rem',
                          marginRight: '-1rem',
                          [theme.media.medium]: {
                            marginLeft: 0,
                            marginRight: 0,
                          },
                        }}
                      >
                        <Img sizes={image.childImageSharp.sizes} />
                      </div>
                    </Flipped>
                  )}
                  <div
                    css={{
                      margin: '3rem 0',
                    }}
                  >
                    <Flipped flipId={`${slug}-title`}>
                      <div>
                        <Flipped inverseFlipId={`${slug}-title`} scale>
                          <Heading component="h1" size={1}>
                            {title}
                          </Heading>
                        </Flipped>
                      </div>
                    </Flipped>

                    <Flipped flipId={`${slug}-author`}>
                      <div>
                        <Flipped inverseFlipId={`${slug}-author`} scale>
                          <div css={{ margin: '1rem 0' }}>
                            {AUTHORS[author]}
                          </div>
                        </Flipped>
                      </div>
                    </Flipped>
                  </div>
                </div>
              </Flipped>
            </div>
          </Flipped>

          <AnimateAfterFlip
            flipping={flipping}
            from={{ opacity: 0, transform: 'translate3d(0px, 15px, 0px)' }}
            to={{ opacity: 1, transform: 'translate3d(0px, 0px, 0px)' }}
          >
            <div>
              <div
                role="main"
                css={theme.sharedStyles.markdown}
                dangerouslySetInnerHTML={{ __html: html }}
              />

              <BlogPostFooter author={author} slug={slug} title={title} />
            </div>
          </AnimateAfterFlip>
        </Container>

        <div css={{ marginTop: 'auto' }}>
          <Footer />
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query BlogPostTemplateQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        slug
        title
        description
        date
        author
        image {
          childImageSharp {
            sizes(maxWidth: 700, maxHeight: 467) {
              ...GatsbyImageSharpSizes_noBase64
            }
          }
        }
      }
    }
  }
`;
