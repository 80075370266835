import { nathanForce } from './avatars/nathanForce';
import jackCross from './avatars/jackCross.jpg';
import jaredPalmer from './avatars/jaredPalmer.jpg';

export const authors = [
  {
    name: 'Jack Cross',
    title: 'Engineer at The Palmer Group',
    avatar: jackCross,
    twitter: '@crosscompile',
  },
  {
    name: 'Nathan Force',
    title: 'UI Engineer at The Palmer Group',
    avatar: nathanForce,
    twitter: '@forcetheissue',
  },
  {
    name: 'Jared Palmer',
    title: 'Senior Engineer at The Palmer Group',
    avatar: jaredPalmer,
    twitter: '@jaredpalmer',
  },
];
