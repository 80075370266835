import React from 'react';
import { authors } from './authors';
import { Author } from './Author';

export const AUTHORS: Record<string, React.ReactNode> = authors.reduce(
  (acc, { name, title, avatar, twitter }) => ({
    [name]: (
      <Author name={name} title={title} avatar={avatar} twitter={twitter} />
    ),
    ...acc,
  }),
  {}
);
