import * as React from 'react';
import { theme } from '../theme';
import * as qs from 'qs';
import { Text } from './Text/Text';
import { CSSProp } from 'glamor';
import { AUTHORS } from './Authors';

export interface BlogPostFooterProps {
  author: string;
  title: string;
  slug: string;
}

const handleShare = (url: string, width: number, height: number) => () => {
  // Calculate the position of the popup so
  // it’s centered on the screen.
  const left = screen.width / 2 - width / 2;
  const top = screen.height / 2 - height / 2;
  window.open(
    url,
    '',
    'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=' +
      width +
      ',height=' +
      height +
      ',top=' +
      top +
      ',left=' +
      left
  );
};

const linkStyle: CSSProp = {
  display: 'block',
  cursor: 'pointer',
  marginRight: '1rem',
  color: '#888b8f',
  fontSize: '1rem',
  fontWeight: 500,
  textTransform: 'uppercase',
  textDecoration: 'none',
  marginLeft: '2.5rem',
  transition: 'color .15s cubic-bezier(.645,.045,.355,1)',
  marginTop: '1.5rem',
};

export const BlogPostFooter: React.SFC<BlogPostFooterProps> = props => {
  const fbUrl =
    'https://www.facebook.com/sharer/sharer.php?' +
    qs.stringify({
      u: `https://palmer.net/blog/` + props.slug,
    });
  const twUrl =
    'https://twitter.com/intent/tweet?' +
    qs.stringify({
      text: props.title,
      url: `https://palmer.net/blog/` + props.slug,
      via: props.author,
    });
  return (
    <div
      css={{
        display: 'flex',
        marginTop: `6rem`,
        marginBottom: `6rem`,
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: `1px solid ${theme.color.offWhiteLighter}`,
      }}
    >
      <div css={{ flex: 3, marginTop: '1.5rem' }}>{AUTHORS[props.author]}</div>
      <div
        css={{
          flex: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          [theme.media.medium]: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          },
        }}
      >
        <a
          css={{
            ...linkStyle,
            ['&:hover' as any]: {
              color: theme.color.twitter,
            },
          }}
          onClick={handleShare(twUrl, 300, 400)}
        >
          <svg
            fill="currentColor"
            viewBox="0 0 24 24"
            aria-hidden="true"
            css={{
              verticalAlign: 'middle',

              height: 20,
              width: 20,
              marginRight: '.5rem',
            }}
          >
            <path d="M23.954 4.569a10 10 0 0 1-2.825.775 4.958 4.958 0 0 0 2.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 0 0-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 0 0-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 0 1-2.228-.616v.061a4.923 4.923 0 0 0 3.946 4.827 4.996 4.996 0 0 1-2.212.085 4.937 4.937 0 0 0 4.604 3.417 9.868 9.868 0 0 1-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 0 0 7.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 0 0 2.46-2.548l-.047-.02z" />
          </svg>
          <Text component="span" size={3} css={{ display: 'inline-block' }}>
            Tweet
          </Text>
        </a>
        <a
          css={{
            ...linkStyle,
            ['&:hover' as any]: {
              color: theme.color.facebook,
            },
          }}
          onClick={handleShare(fbUrl, 300, 400)}
        >
          <svg
            fill="currentColor"
            viewBox="0 0 38 38"
            aria-hidden="true"
            css={{
              verticalAlign: 'middle',
              height: 20,
              width: 20,
              marginRight: '.5rem',
            }}
          >
            <path d="M36.5 0h-35C.3 0 0 .3 0 1.5v34.9C0 37.7.3 38 1.5 38h18.9V23.4h-5.8v-5.8h5.8v-4.4c0-5.1 2.8-7.8 7.4-7.8 2.2 0 4.3.2 4.3.2v4.7h-2.8c-2.5 0-3.1 1.8-3.1 3.5v3.8h6l-.8 5.8h-5.3V38h10.2c1.2 0 1.5-.3 1.5-1.5v-35C38 .3 37.7 0 36.5 0z" />
          </svg>
          <Text component="span" size={3} css={{ display: 'inline-block' }}>
            Share
          </Text>
        </a>
      </div>
    </div>
  );
};

(BlogPostFooter as any).displayName = 'BlogFooter';
