import React from 'react';
import { Spring, animated } from 'react-spring';

export interface AnimateAfterFlipProps {
  flipping?: boolean;
  from: any;
  to: any;
}

export const AnimateAfterFlip: React.SFC<AnimateAfterFlipProps> = ({
  flipping,
  from,
  to,
  children,
}) => {
  if (flipping === true) {
    return <div style={from}>{children}</div>;
  } else if (flipping === false) {
    return (
      <Spring native from={from} to={to}>
        {style => <animated.div style={style}>{children}</animated.div>}
      </Spring>
    );
  }

  return <>{children}</>;
};
