import React from 'react';
import { theme } from '../../theme';
import { Text } from '../Text/Text';
import { Helmet } from 'react-helmet';

interface AuthorProps {
  name: string;
  title: string;
  avatar: string;
  twitter: string;
}

export const Author: React.SFC<AuthorProps> = ({
  name,
  title,
  avatar,
  twitter,
}) => (
  <React.Fragment>
    <Helmet>
      <meta name="twitter:creator" content={twitter} />
    </Helmet>
    <div css={{ display: 'flex', alignItems: 'center' }}>
      <img
        css={{
          height: 36,
          width: 36,
          borderRadius: '50%',
        }}
        src={avatar}
      />
      <div
        css={{
          marginLeft: '1rem',
        }}
      >
        <Text>{name}</Text>
        <Text
          size={3}
          css={{
            color: theme.color.grayLighter,
          }}
        >
          {title}
        </Text>
      </div>
    </div>
  </React.Fragment>
);
